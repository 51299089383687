var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"projects-types"}},[_c('h3',{staticClass:"ui header"},[_vm._v(" Créer un projet à partir d'un modèle disponible: ")]),_c('div',{staticClass:"ui divided items"},[_vm._l((_vm.project_types),function(project){return _c('div',{key:project.slug,staticClass:"item"},[_c('div',{staticClass:"ui tiny image"},[_c('img',{attrs:{"src":project.thumbnail.includes('default')
              ? require('@/assets/img/default.png')
              : _vm.DJANGO_BASE_URL + project.thumbnail + _vm.refreshId(),"alt":"Image associé au projet"}})]),_c('div',{staticClass:"middle aligned content"},[_c('div',{staticClass:"description"},[_c('router-link',{attrs:{"to":{
              name: 'project_create_from',
              params: {
                slug: project.slug,
              },
            }}},[_vm._v(" "+_vm._s(project.title)+" ")]),_c('p',[_vm._v(_vm._s(project.description))]),_c('strong',[_vm._v("Projet "+_vm._s(project.moderation ? '' : 'non')+" modéré")])],1),_c('div',{staticClass:"meta"},[_c('span',{attrs:{"data-tooltip":"Date de création"}},[_vm._v(" "+_vm._s(project.created_on)+"  "),_c('i',{staticClass:"calendar icon",attrs:{"aria-hidden":"true"}})])]),_c('div',{staticClass:"meta"},[_c('span',{attrs:{"data-tooltip":"Visibilité des signalement publiés"}},[_vm._v(" "+_vm._s(project.access_level_pub_feature)+" "),_c('i',{staticClass:"eye icon",attrs:{"aria-hidden":"true"}})]),_c('span',{attrs:{"data-tooltip":"Visibilité des signalement archivés"}},[_vm._v(" "+_vm._s(project.access_level_arch_feature)+" "),_c('i',{staticClass:"archive icon",attrs:{"aria-hidden":"true"}})])])])])}),(!_vm.project_types || _vm.project_types.length === 0)?_c('span',[_vm._v("Aucun projet type n'est défini.")]):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }